<template>
<van-field v-bind="$attrs" @[trigger]="onValid">
  <template #[slotName] v-for="(slot, slotName) in $slots" >
    <slot :name="slotName" />
  </template>
</van-field>
</template>

<script>
import {useAttrs} from "vue";
import {Field, Toast} from "vant";
import validator from "@/utils/formsValidator";

export default {
  name: "ValidatorField",
  components:{
    [Field.name]:Field
  },
  setup(props,context){
    const attr = useAttrs()
    const trigger = attr['validator-trigger'] ? attr['validator-trigger'] : 'blur'
    const onValid = (e)=>{
      let {isValid,msg} = validator.isValid(e.target.value, attr.validator)
      if(isValid){
        context.emit('validSuccess')
      }else{
        context.emit('validFail')
        Toast(msg)
      }

    }
    return {
      attr,
      trigger,
      onValid,
      context
    }
  }
}
</script>

<style scoped>

</style>
