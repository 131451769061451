<template>
<van-form v-bind="$attrs" @submit="onsubmit">
  <slot></slot>
</van-form>
</template>

<script>
import {Form, Toast} from "vant";
import {useAttrs} from "vue";
import validator from "@/utils/formsValidator";

export default {
  name: "ValidatorForm",
  components:{
    [Form.name]:Form
  },
  setup(props,context){
    const attr = useAttrs()
    const onsubmit = (data)=>{
      let ret = validator.validator(data, attr.rules)
      if(ret.isValid){
        context.emit('valid-success',data)
      }else{
        Toast(ret.msg)
        context.emit('valid-failed',ret)
      }
    }
    return {
      context,
      onsubmit
    }
  }
}
</script>

<style scoped>

</style>
