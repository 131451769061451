<template>
  <div class="dialog-pane-block">
    <div class="sub-list-pane" v-if="!addSubPaneShow">
      <van-nav-bar class="dialog-pane-bar" title="请选择登录小号" left-arrow @click-left="loginOut()"
                   @click-right="addSubPaneShow=true">
        <template #right>
          <van-icon name="add-o"/>
        </template>
      </van-nav-bar>
      <div class="has-bar-pane-content">
        <van-list class="sub-list-box">
          <van-cell v-for="item in list" :key="item.id" @click="openSmallLogin(item.id)" value="进入" is-link>
            <!-- 使用 title 插槽来自定义标题 -->
            <template #title>
              <span class="van-ellipsis sub-title" :class="item.is_last ? 'has-last-tag':''">{{ item.nickname }}</span>
              <span class="sub-tag" v-if="item.is_last">上次登录</span>
            </template>
          </van-cell>
        </van-list>
      </div>
    </div>
    <div class="add-sub-pane" v-if="addSubPaneShow">
      <van-nav-bar class="dialog-pane-bar" title="添加小号" left-arrow @click-left="addSubPaneShow=false"/>
      <div class="has-bar-pane-content">
        <van-cell-group>
          <validator-field v-model="smallNickName" :validator="smallNickNameRules" placeholder="小号名称不得大于12个字符" @validSuccess="isAddValid = false" @validFail="isAddValid = true"/>
        </van-cell-group>
        <div class="cell-button">
          <van-button round block type="primary" size="small" @click="addSmallEvent" :disabled="isAddValid">
            确认
          </van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {List, Cell, Field, CellGroup, NavBar, Dialog, Icon, Button, Toast} from 'vant';
import {reactive, ref} from "vue";
import {smallList, addSmall, smallLogin} from "@/api/user";
import {useStore} from "vuex";
import ValidatorField from "@/components/ValidatorField";

export default {
  name: "SmallList",
  components: {
    ValidatorField,
    [List.name]: List,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [Button.name]: Button,
    [NavBar.name]: NavBar,
    [CellGroup.name]: CellGroup,
    [Icon.name]: Icon,
    [Dialog.Component.name]: Dialog.Component,
  },
  setup(prop, context) {
    const store = useStore();
    const list = ref([]);
    const smallNickName = ref('');
    const addSubPaneShow = ref(false);
    const smallNickNameRules = reactive({
      label: '小号名称',
      rules: 'required;length(~12,true)'
    })
    const isAddValid = ref(true)

    // 加载小号列表
    const getSubList = () => {
      Toast.loading({
        message: "加载中...",
        overlay: true,
        duration: 500,
        forbidClick: true,
      });
      smallList().then((res) => {
        list.value = res.data
      });
    };
    getSubList()
    //添加小号
    const addSmallEvent = () => {
      addSmall(smallNickName.value).then((res) => {
        Toast(res.message)
        addSubPaneShow.value = false
        getSubList()
      })
    };
    //返回登陆
    const loginOut = () => {
      store.commit('logout')
      context.emit('exit')
    };
    //小号登录
    const smallLoginPopup = ref()
    const openSmallLogin = (id) => {
      smallLogin(id).then((res) => {
        store.commit('setSmallId', id);
        context.emit('smallLoginSuccess', {id, token: res.data.token})
      })
    };

    return {
      list,
      addSubPaneShow,
      smallNickName,
      smallLoginPopup,
      smallNickNameRules,
      isAddValid,
      getSubList,
      addSmallEvent,
      loginOut,
      openSmallLogin,
    };
  },
}
</script>

<style scoped>
.sub-list-box {
  overflow-x: hidden;
  overflow-y: auto;
  height: 190px;
}

.sub-list-box .van-cell {
  height: 44px;
  background: #F7F8FA;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  color: #4E5969;
  align-items: center;
  padding: 0px 12px;
  margin-bottom: 12px;
}

.sub-list-box :deep(.van-cell__title) {
  flex: auto;
  text-align: left;
  max-width: 224px;
  display: flex;
  align-items: center;
}

.sub-title {
  display: inline-block;
}

.has-last-tag {
  max-width: 160px;
}

.sub-tag {
  display: inline-block;
  padding: 0px 5px;
  height: 22px;
  line-height: 22px;
  background: #E9F3FF;
  font-size: 12px;
  color: #4183FA;
  border-radius: 2px;
  margin-left: 6px;

}

:deep(.van-cell__right-icon) {
  font-size: 10px;
  margin-left: 0px;
  color: #4E5969;
}

.add-sub-pane .cell-button {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
}
/*横屏*/
.land-dialog-pane .sub-list-box {
  height:130px;
}
.land-dialog-pane .sub-list-box .van-cell {
  height:26px;
  font-size: 10px;
  padding: 0px 8px;
  margin-bottom: 8px;
}

.land-dialog-pane .sub-list-box :deep(.van-cell__title) {
  max-width: 156px;
}
.land-dialog-pane .has-last-tag {
  max-width: 112px;
}
.land-dialog-pane .sub-tag {
  padding: 0px 3px;
  height: 15px;
  line-height: 15px;
  font-size: 8px;
  margin-left: 4px;
}
.land-dialog-pane :deep(.van-cell__right-icon) {
  font-size: 7px;
}

.land-dialog-pane .add-sub-pane .cell-button {
  position: absolute;
  bottom:14px;
  left: 14px;
  right: 14px;
}
</style>
