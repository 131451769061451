
// cp登陆回调
export const gameLoginCallback = (user_id, token, iframe) => {
    const defaults = {
        action: "callback:login",
        user_id: user_id,
        token: token
    };
    iframe.contentWindow.postMessage(defaults, '*')
}

// cp登陆回调
export const gameLogoutCallback = (iframe) => {
    const defaults = {
        action: "callback:logout",
    };
    iframe.contentWindow.postMessage(defaults, '*')
}
