//获取短信模块
import request from '../utils/request'
//发送手机登陆验证码
export function smsRegister(phone) {
    return request({
        method: 'post',
        url: '/sms/register',
        data: {phone},
    })
}
//发送忘记密码验证码
export function sendSmsForgetPass(phone) {
    return request({
        method: 'post',
        url: '/sms/forget-pass',
        data: {phone},
    })
}
//发送绑定/换绑手机号验证码
export function sendSmsChangeMobile(data) {
    return request({
        method: 'post',
        url: '/sms/change-mobile',
        data,
    })
}
//发送解绑验证码
export function sendSmsRemoveMobile(phone) {
    return request({
        method: 'post',
        url: '/sms/remove-mobile',
        data:{phone},
    })
}
