<template>
  <div v-if="payType==='h5'">
    <van-loading class="m20" size="60px" type="spinner" color="#1989fa">支付中...</van-loading>
    <div class="notice-text">如不能打开支付，请确认是否安装支付应用</div>
    <iframe sandbox="allow-top-navigation allow-scripts allow-top-navigation allow-same-origin allow-forms"
            v-if="payUrl && !safari" :src="payUrl" style="width: 0;height: 0; border: 0"></iframe>
  </div>
  <div v-else>
    <van-tabs v-model:active="ewmType" @click-tab="changTab">
      <van-tab title="支付宝" name="alipay">
        <div class="ewmbox"></div>
        <vue-qr :text="payUrl" class="ewm"></vue-qr>
      </van-tab>
      <van-tab title="微信支付" name="wechat">
        <vue-qr :text="payUrl" class="ewm"></vue-qr>
      </van-tab>
    </van-tabs>
    <div class="notice-text">请使用扫码支付，付款过程中请勿关闭此弹窗</div>
  </div>

</template>

<script>
import {Button, Loading, Tabs, Tab, Toast} from 'vant';
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import {checkOrder} from "@/api/spend";
import {payCallback} from "@/utils/callback";
import {ref, computed, onMounted} from "vue";
import {getStoreItem} from "@/utils/function";
import {isSafari} from "@/utils/devices";

export default {
  name: "PayLoading",
  components: {
    [Loading.name]: Loading,
    [Button.name]: Button,
    [Toast.name]: Toast,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    vueQr,
  },
  props: {
    payUrl: {
      type: String,
      default: '',
    },
    payType: {
      type: String,
      default: 'h5',
    }
  },
  setup(props, context) {
    const safari = computed(() => {
      return isSafari()
    })
    const ewmType = ref(props.payType);
    const pay_order_number = getStoreItem('pay_order_number');

    const timer = setInterval(() => {
      // 这里调用调用需要执行的方法，1为自定义的参数，由于特殊的需求它将用来区分，定时器调用和手工调用，然后执行不同的业务逻辑
      checkOrderStatus()
    }, 2000) // 每两秒执行1次

    const checkOrderStatus = (close = false) => {
      checkOrder(pay_order_number).then(res => {
        const {pay_status} = res.data;
        const {cp_order_number} = res.data;
        if (pay_status === 1 || close) {
          clearInterval(timer)
          payCallback(pay_status, cp_order_number);
        }
      })
    }

    //切换扫码方式
    const changTab = ({name}) => {
      ewmType.value = name
      context.emit('changeEwmType', ewmType)
    }

    onMounted(() => {
      if (safari.value) {
        window.open(props.payUrl, '_blank')
      }
    })


    return {
      safari,
      timer,
      ewmType,
      changTab,
      checkOrderStatus,
      context
    };
  },
}
</script>

<style scoped>
:deep(.van-tab) {
  color: #A5ADB7;
  font-size: 16px;
}

:deep(.van-tabs__wrap) {
  height: 50px;
}

:deep(.van-tab--active) {
  font-size: 18px;
  font-weight: 500;
  color: #1D2129;
  line-height: 25px;
}

:deep(.van-tabs__nav--line) {
  padding-bottom: 0px;
  position: relative;
}

:deep(.van-tabs__line) {
  width: 20px;
  height: 3px;
  background: #4983FE;
  border-radius: 3px;
  bottom: 0px;
}

.ewm {
  width: 245px;
  height: 245px;
  border: 1px solid #E5E6EB;
  margin: 20px auto;
}

.notice-text {
  font-size: 12px;
  font-weight: 400;
  color: #A5ADB7;
  line-height: 20px;
}

</style>
