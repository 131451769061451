//订单请求模块
import request from '../utils/request'
//创建订单
export const createOrder = (data) => {
    return request({
        method: 'post',
        url: '/spend/create-order',
        data,
    })
}
// 支付详情
export const payInit = (pay_order_number) => {
    return request({
        method: 'post',
        url: '/pay/pay-init',
        data: {pay_order_number},
    })
}
//查询订单状态
export const checkOrder = (pay_order_number) => {
    return request({
        method: 'post',
        url: '/spend/check-order',
        data: {pay_order_number},
    })
}
//查询订单状态
export const orderList = (data) => {
    return request({
        method: 'post',
        url: '/spend/order-list',
        data,
    })
}
