<template>
  <div class="main gray-bg has-bottom">
    <van-nav-bar left-arrow @click-left="onClickLeft" class="top-bar" title="选择优惠券"/>
    <van-radio-group v-model="chooseCoupon.id">
      <van-cell-group class="coupon-list">
        <van-cell v-for="(item, key) in couponList" :key="key" class="coupon-cell" @click="selectCoupon(item)"
                  clickable>
          <template #title>
            <div class="coupon-title">
              <div class="coupon-num van-ellipsis">￥<span>{{ item.money }}</span></div>
              <div class="van-multi-ellipsis--l2 coupon-text">满{{ item.limit_money }}元可用</div>
            </div>
          </template>
          <template #value>
            <div class="van-multi-ellipsis--l2 coupon-rule">适用范围：{{ item.coupon_name }}</div>
            <div class="van-multi-ellipsis--l2 coupon-date">{{ date(item.start_time, 'Y/m/d') }} -
              {{ item.end_time ? date(item.end_time, 'Y/m/d') : '长期有效' }}
            </div>
          </template>
          <template #right-icon>
            <van-radio :name="item.id" @click.stop="selectCoupon(item)"/>
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>
    <!--底部按钮-->
    <div class="bottom-bar flex align-center justify-between">
      <van-button type="primary" round @click="confirmChoose">确定</van-button>
    </div>
    <!--底部按钮-->
  </div>
</template>

<script>
//todo 滚动加载
import {NavBar, Cell, CellGroup, Button, RadioGroup, Radio} from "vant"
import {reactive, ref, toRefs} from "vue";
import {payCouponList} from "../../api/coupon";
import {date} from "../../utils/function";

export default {
  name: "Coupon",
  components: {
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
  },
  props: {
    coupon: {
      type: Object,
      default: () => {
        return {
          id: 0,
          amount: 0,
        }
      }
    },
    orderNumber: String,
  },
  setup(props, context) {
    //初始化
    const {coupon, orderNumber} = toRefs(props);// eslint-disable-line no-unused-vars
    const couponList = ref([])
    const chooseCoupon = reactive({
      id: coupon.value.id || 0,
      amount: coupon.value.amount || 0
    })
    const chooseId = ref(coupon.value.id || 0)
    payCouponList(orderNumber.value).then(({data}) => {
      couponList.value = data.list
    })
    //选择优惠券
    const selectCoupon = (item) => {
      if (item.id === chooseCoupon.id && chooseId.value === chooseCoupon.id) {
        chooseCoupon.id = 0
        chooseCoupon.amount = 0
        chooseId.value = 0
      } else {
        chooseCoupon.id = item.id
        chooseCoupon.amount = item.money
        chooseId.value = item.id
      }
    }
    //返回订单
    const onClickLeft = () => {
      context.emit('closeCouponPopup', coupon.value)
    }
    //优惠券确认操作
    const confirmChoose = () => {
      context.emit('closeCouponPopup', chooseCoupon)
    }
    return {
      chooseCoupon,
      couponList,
      chooseId,
      onClickLeft,
      confirmChoose,
      selectCoupon,
      date,
      context
    }
  }
}
</script>

<style scoped>
.coupon-list {
  padding: 12px 15px;
  background: none;
}

.coupon-cell {
  padding: 0px;
  padding-right: 12px;
  background: url("../../assets/images/coupon/value-coupon.png") right no-repeat;
  background-size: calc(100% - 105px) 100%;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 12px;
  border-radius: 20px;
  overflow: hidden;
  align-items: center;
}

.coupon-cell :deep(.van-cell__title) {
  width: 105px;
  background: url("../../assets/images/coupon/blue-coupon.png") center no-repeat;
  background-size: 100% 100%;
  flex: unset;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 12px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.coupon-title {
  width: 100%;
}

.coupon-num {
  font-weight: 600;
  font-size: 14px;
  line-height: 40px;
}

.coupon-num span {
  font-size: 40px;
}

.coupon-text {
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
}

.coupon-cell :deep(.van-cell__value) {
  text-align: left;
  padding: 12px;
}

.coupon-rule {
  font-size: 16px;
  font-weight: 500;
  color: #1D2129;
  line-height: 22px;
}

.coupon-date {
  font-size: 11px;
  font-weight: 400;
  color: #4E5969;
  line-height: 16px;
  margin-top: 8px;
}

.bottom-bar .van-button {
  border-radius: 25px;
  width: 100%;
  height: 50px;
  font-size: 17px;
}
</style>
