<template>
  <div class="dialog-pane-form">
    <validator-form @valid-success="onSubmit" :rules="userFormRules">
      <van-cell-group>
        <!--用户账号 -->
        <validator-field v-model="user.username" name="username" :validator="userFormRules.username"
                         placeholder="字母开头6-15位字母和数字" clearable
                         :left-icon="require('@/assets/images/login/user.png')"></validator-field>
        <!--密码 -->
        <validator-field v-model="user.password" name="password" :validator="userFormRules.password"
                         :right-icon="switchPassType ? require('@/assets/images/login/eye.png') : require('@/assets/images/login/closed-eye.png')"
                         :left-icon="require('@/assets/images/login/pass.png')"
                         @click-right-icon="switchPassType = !switchPassType"
                         :type="switchPassType ? 'text' : 'password'"
                         placeholder="6-15位数字或英文" clearable/>
      </van-cell-group>
      <div class="cell-agree">
        <van-checkbox v-model="agreeChecked">我已阅读并同意<a :href="initData.agreement_url"
                                                       target="_blank">《{{ initData.agreement_title }}》</a>
        </van-checkbox>
      </div>
      <div class="cell-button">
        <van-button round block type="primary" native-type="submit" size="small" :disabled="!agreeChecked">
          进入游戏
        </van-button>
      </div>

    </validator-form>
  </div>
</template>

<script>
import {CellGroup, Button, Checkbox, NavBar, Toast} from 'vant';
import {ref, toRefs, reactive} from "vue";
import {smallRegister} from '@/api/user'
import {useStore} from "vuex"
import {isUnique} from "@/utils/function";
import ValidatorField from "@/components/ValidatorField";
import ValidatorForm from "@/components/ValidatorForm";

export default {
  name: "AccountRegister",
  components: {
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [NavBar.name]: NavBar,
    ValidatorForm,
    ValidatorField,
  },
  props: {
    initData: {
      type: Object,
      default: () => {
        return {
          agreement_url: "",
          agreement_title: "用户协议和隐私政策",
          agreement_default: "1"
        }
      }
    },
    randAccount: String,
  },
  setup(props, context) {
    const {initData, randAccount} = toRefs(props);// eslint-disable-line no-unused-vars
    const agreeChecked = ref(true);
    //store声明
    const store = useStore();
    const user = reactive({
      password: '', //密码
      username: randAccount || '', //用户名
    });

    //密码显示与隐藏
    const switchPassType = ref(false);
    const userFormRules = {
      username: {
        label: '用户名',
        rules: "required;sdkPassword",
      },
      password:{
        label: '密码',
        rules: 'required;password'
      }
    };


    //提交表单
    const onSubmit = () => {
      Toast.loading({
        message: "登录中...",
        forbidClick: true,
      });
      smallRegister({
        account: user.username,
        password: user.password,
      }).then((res) => {
        //历史用户
        const historyUsers = store.state.historyUserList;
        ///没有历史用户
        if (!historyUsers) {
          const userList = [];
          userList.push(user)
          store.commit('setHistoryUserList', userList)
        } else {
          //查找重复历史用户
          const userIndexOf = isUnique(historyUsers, 'username', user.username);
          if (userIndexOf > -1) {
            historyUsers[userIndexOf] = user
          } else {
            historyUsers.push(user);
          }
          //历史用户超过5个删除数据第一个
          if (historyUsers.length > 5) {
            historyUsers.shift()
          }
          store.commit('setHistoryUserList', historyUsers)
        }
        context.emit('loginSuccess', res.data)
      })
    };
    return {
      agreeChecked,
      userFormRules,
      switchPassType,
      user,

      onSubmit,
      context
    }
  }
}
</script>

<style scoped>
</style>
