<template>
  <div class="dialog-pane-block">
    <van-nav-bar class="dialog-pane-bar" title="防沉迷系统" @click-right="close">
      <template #right v-if="!antiIsForce">
        <van-icon name="close"/>
      </template>
    </van-nav-bar>
    <div class="pane-header">
      <div class="tips-title">根据国家相关规定，游戏用户需进行实名认证，未成年玩家将会受到一下游戏健康限制：</div>
      <div class="tips-ol">1、某些游戏时间段将受到限制</div>
      <div class="tips-ol">2、游戏充值金额将受到限制</div>
    </div>
    <van-form @submit="onSubmit" ref="form" class="dialog-pane-form">
      <van-cell-group>
        <!--手机号-->
        <van-field v-model="user.real_name" name="real_name" placeholder="请输入真实姓名" clearable/>
        <!--验证码-->
        <van-field v-model="user.id_card" placeholder="请输入身份证号"/>
      </van-cell-group>
      <div class="cell-button">
        <van-button round block type="primary" native-type="submit" size="small">
          立即认证
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import {NavBar, Icon, Field, CellGroup, Button, Form} from 'vant'
import {toRefs, reactive} from "vue";
import {changeIdcard} from "@/api/user";
import {Toast} from "vant";

export default {
  name: "AntiAddiction",
  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Form.name]: Form,
  },
  props: {
    antiIsForce: Boolean
  },
  setup(props, context) {
    const {antiIsForce} = toRefs(props);// eslint-disable-line no-unused-vars
    const user = reactive({
      real_name: '',
      id_card: ''
    })
    //关闭实名认证弹窗
    const close = () => {
      context.emit('closeAntiDialog');
    }

    const onSubmit = () => {
      Toast.loading({
        message: "提交中...",
        forbidClick: true,
      });
      changeIdcard(user).then((res) => {
        Toast(res.message);
        context.emit('closeAntiDialog');
      })
    }
    return {
      user,
      close,
      onSubmit,
      props,
      context
    }
  }
}
</script>

<style scoped>
.pane-header {
  padding: 0px 20px;
  text-align: left;
}
.tips-title {
  font-size: 14px;
  font-weight: 400;
  color: #4E5969;
  line-height: 21px;
}
.tips-ol {
  font-size: 14px;
  font-weight: 400;
  color: #86909C;
  line-height: 21px;
}

/*横屏*/
.land-dialog-pane .pane-header {
  padding: 0px 14px;
}

.land-dialog-pane .tips-title {
  font-size: 10px;
  line-height: 14px;
}
.land-dialog-pane .tips-ol {
  font-size: 10px;
  line-height: 14px;
}
</style>
