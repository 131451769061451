<template>
  <div class="dialog-pane-block">
    <van-nav-bar class="dialog-pane-bar" title="系统公告" @click-right="close">
      <template #right v-if="props.params?.close">
        <van-icon name="close"/>
      </template>
    </van-nav-bar>
    <div class="pane-container">{{props.params?.tips || '暂无内容'}}</div>
    <div class="cell-button" v-if="props.params?.type && params?.type == 'url' && params?.url">
      <van-button type="primary" round @click="toLink(props.params?.url)">查看详情</van-button>
    </div>
  </div>
</template>

<script>
import {Button, Icon, NavBar} from 'vant';
export default {
  name: "NoticeDialog",
  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Button.name]: Button
  },
}
</script>
<script setup>
import {defineProps,defineEmits} from "vue";
const emits = defineEmits(['close'])
const props = defineProps({
  params:Object
})

const toLink = (url) => {
  window.top.open(url,'_blank')
}
const close = () => {
  emits('close')
}
</script>

<style scoped>
.dialog-pane-block{
  height:60vh;
}
.pane-container{
  height:calc(60vh - 114px);
  padding: 0px 20px;
  font-size: 12px;
  color: #4E5969;
  line-height: 21px;
  overflow-y: auto;
}
.cell-button{
  padding: 0px 20px;
  box-sizing: border-box;
}
.cell-button .van-button{
  width: 100%;
}
/*横屏*/
.land-dialog-pane .dialog-pane-block{
  height:96vh;
}
.land-dialog-pane .pane-container{
  height:calc(96vh - 90px);
}
</style>
