<template>
  <div class="flex align-center justify-center dialog-pane-block">
    <van-form @submit="onSubmit" ref="form" class="dialog-pane-container">
      <van-nav-bar class="dialog-pane-bar" title="设置密码" left-arrow @click-left="showLogin"/>
      <div class="has-bar-pane-content">
        <van-cell-group>
          <!--密码 -->
          <van-field v-model="query.password" :rules="userFormRules.password" placeholder="6-15位数字或英文" type="password"
                     clearable/>
          <!--重复密码-->
          <van-field v-model="query.password_confirm" :rules="userFormRules.password_confirm" type="password"
                     placeholder="请再次输入确认密码" clearable/>
        </van-cell-group>
        <div class="cell-button">
          <van-button round block type="primary" native-type="submit" size="small">
            确认
          </van-button>
        </div>
      </div>
    </van-form>
  </div>
</template>

<script>
import {Field, CellGroup, Button, Form, NavBar} from 'vant';
export default {
  name: "SetPassword",
  components: {
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Form.name]: Form,
    [NavBar.name]: NavBar,
  },
}
</script>
<script setup>
import {reactive,defineEmits,defineProps} from 'vue';
import "@/style/loginDialog.css"
import {changePass} from "@/api/user";

const emits = defineEmits(['callback','showLogin'])
const props = defineProps({
  loginResult:{
    type:[Object,String]
  }
})

const query = reactive({
  old_password: "",
  password: "",
  password_confirm: ""
})
//密码校验
const validatePass = (value) => {
  if (!value) {
    return '请输入密码'
  } else if (value !== query.password) {
    return '两次密码不一致'
  } else {
    return true
  }
};
const userFormRules = {
  password: [
    {required: true, message: "请输入密码"},
    {pattern: /^[a-zA-Z0-9]{6,15}$/, message: "密码为6-15位数字或英文"},
  ],
  password_confirm: [
    {required: true, message: '请输入密码', trigger: 'onBlur', validator: validatePass},
  ],
};
const onSubmit = () => {
  changePass(query,{
    user_id:props?.loginResult?.user_id,
    token:props?.loginResult?.token,
  }).then(()=>{
    emits('callback')
  })
};
const showLogin = () => {
  emits("showLogin")
}
</script>

<style scoped>
</style>
