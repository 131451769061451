//工具请求模块
import request from '../utils/request'

//支付订单
export const paySpend = (data) => {
    return request({
        method: 'post',
        url: '/pay/pay-spend',
        data,
    })
}