<template>
  <div class="flex align-center justify-center dialog-pane-block">
    <van-form @submit="onSubmit" ref="form" class="dialog-pane-container">
      <van-nav-bar class="dialog-pane-bar" title="忘记密码" left-arrow @click-left="showLogin"/>
      <div class="has-bar-pane-content">
        <van-cell-group>
          <!--手机号-->
          <van-field v-model="user.phone" :rules="userFormRules.phone" name="phone" placeholder="请输入手机号"
                     clearable/>
          <!--验证码-->
          <van-field v-model="user.sms_code" :rules="userFormRules.sms_code" clearable placeholder="请输入短信验证码"
                     class="addon-field">
            <template #button>
              <div class="input-addon" @click="sendValidateCode" :class="isDisabled ? 'not-click' : ''">
                {{ isDisabled ? `${count}s后重新获取` : "获取验证码" }}
              </div>
            </template>
          </van-field>
          <!--密码 -->
          <van-field v-model="user.password" :rules="userFormRules.password" placeholder="6-15位数字或英文" type="password"
                     clearable/>
          <!--重复密码-->
          <van-field v-model="user.password_confirm" :rules="userFormRules.password_confirm" type="password"
                     placeholder="请再次输入确认密码" clearable/>
        </van-cell-group>
        <div class="cell-button">
          <van-button round block type="primary" native-type="submit" size="small">
            确认
          </van-button>
        </div>
      </div>
    </van-form>
  </div>
</template>

<script>
import {Field, CellGroup, Button, Form, NavBar,} from 'vant';
import {ref, reactive} from 'vue';
import {useStore} from 'vuex'
import {sendSmsForgetPass} from '@/api/verification'
import {checkPhoneForgetPass} from '@/api/user'
import "@/style/loginDialog.css"


export default {
  name: "ForgetPassword",
  components: {
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Form.name]: Form,
    [NavBar.name]: NavBar,
  },
  setup(props, context) {
    //store声明
    const store = useStore();
    const user = reactive({
      sms_code: '',
      phone: store.state.lastLoginPhone || '',
      password: '',
      password_confirm: '',
    })
    //倒计时
    const timer = ref();
    const count = ref();
    const isDisabled = ref(false);
    const form = ref();

    //密码校验
    const validatePass = (value) => {
      if (!value) {
        return '请输入密码'
      } else if (value !== user.password) {
        return '两次密码不一致'
      } else {
        return true
      }
    };
    //表单校验规则
    const userFormRules = {
      phone: [
        {required: true, message: "请输入手机号"},
        {
          pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
          message: "手机号格式错误"
        },
      ],
      sms_code: [
        {required: true, message: "请输入验证码"},
        {pattern: /^\d+$/, message: "验证码格式错误"},
      ],
      password: [
        {required: true, message: "请输入密码"},
        {pattern: /^[a-zA-Z0-9]{6,15}$/, message: "密码为6-15位数字或英文"},
      ],
      password_confirm: [
        {required: true, message: '请输入密码', trigger: 'onBlur', validator: validatePass},
      ],
    };
    //发送验证码倒计时
    const sendValidateCode = () => {
      form.value.validate('phone').then(() => {
        count.value = 60
        isDisabled.value = true
        return sendSmsForgetPass(user.phone)
      }).then(() => {
        timer.value = setInterval(() => {
          if (count.value > 0) {
            count.value--;
          } else {
            isDisabled.value = false;
            clearInterval(timer.value);
          }
        }, 1000)
      });
    }

    //表单提交
    const onSubmit = () => {
      checkPhoneForgetPass(user).then(() => {
        showLogin()
      });
    };

    const showLogin = () => {
      context.emit("showLogin")
    }

    return {
      userFormRules,
      timer,
      count,
      isDisabled,
      user,
      form,

      onSubmit,
      sendValidateCode,
      showLogin,
      context
    }
  },
}
</script>

<style scoped>
</style>
