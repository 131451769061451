<template>
  <!--登陆窗口-->
  <van-popup v-if="showLoginVisible" :show="showLoginVisible"
             :class="!isMobileLandscape ? 'default-dialog-pane' : 'land-dialog-pane'">
    <div class="flex align-center justify-center dialog-pane-block">
      <van-tabs v-model:active="activeName" @click-tab="changTab" class="dialog-pane-container">
        <van-tab title="账号登录" name="accountLogin">
          <account-login :initData="initData" @loginSuccess="loginSuccess"
                         @forgetPass="showForgetPassword"></account-login>
        </van-tab>
        <van-tab title="手机登录" name="phoneRegister">
          <phone-register :initData="initData" @loginSuccess="loginSuccess"></phone-register>
        </van-tab>
        <van-tab title="快速注册" name="accountRegister">
          <account-register :initData="initData" :randAccount="fastCreateAccount"
                            @loginSuccess="loginSuccess"></account-register>
        </van-tab>
      </van-tabs>
    </div>
  </van-popup>
  <!--设置密码弹窗-->
  <van-popup v-if="showSetPasswordVisible" :show="showSetPasswordVisible"
             :class="!isMobileLandscape ? 'default-dialog-pane' : 'land-dialog-pane'">
    <set-password :loginResult="loginResult" @callback="setPasswordCallback" @showLogin="showLogin"></set-password>
  </van-popup>
  <!--忘记密码弹窗-->
  <van-popup v-if="showForgetPasswordVisible" :show="showForgetPasswordVisible"
             :class="!isMobileLandscape ? 'default-dialog-pane' : 'land-dialog-pane'">
    <ForgetPassword @showLogin="showLogin"/>
  </van-popup>
  <!--选择小号-->
  <van-popup v-if="showSmallListVisible" :show="showSmallListVisible"
             :class="!isMobileLandscape ? 'default-dialog-pane' : 'land-dialog-pane'">
    <SmallList @exit="showLogin" @smallLoginSuccess="smallLoginSuccess"/>
  </van-popup>
  <!--防沉迷-->
  <van-popup v-if="showAntiVisible" :show="showAntiVisible"
             :class="!isMobileLandscape ? 'default-dialog-pane' : 'land-dialog-pane'">
    <AntiAddiction :antiIsForce="antiIsForce" @closeAntiDialog="closeAntiDialog"/>
  </van-popup>
  <!--公告通知-->
  <van-popup v-if="showNoticeVisible" :show="showNoticeVisible"
             :class="!isMobileLandscape ? 'default-dialog-pane' : 'land-dialog-pane'">
    <NoticeDialog :params="noticeData" @close="closeNoticeDialog"></NoticeDialog>
  </van-popup>
</template>

<script>
import {Tab, Tabs,Popup} from "vant";
export default {
  name: "Login",
  components: {
    [Popup.name]: Popup,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
  }
}
</script>
<script setup>
import AccountLogin from "@/views/login/AccountLogin";
import AccountRegister from "@/views/login/AccountRegister";
import PhoneRegister from "@/views/login/PhoneRegister";
import {ref, reactive, computed, onMounted,defineExpose} from "vue";
import {realAuthStatus, tabsInit, toolNotice} from '@/api/tool'
import {getTime} from '@/utils/make'
import {useStore} from "vuex";
import "@/style/loginDialog.css"
import {is_mobile, isLandscape} from "@/utils/devices";
import SmallList from "@/views/login/SmallList";
import AntiAddiction from "@/views/login/AntiAddiction";
import {loginCallback} from "@/utils/callback";
import NoticeDialog from "@/views/login/NoticeDialog";
import ForgetPassword from "@/views/login/ForgetPassword";
import SetPassword from "@/views/login/SetPassword";
import {Toast} from "vant";

const store = useStore();

const isMobileLandscape = is_mobile() && isLandscape()
const showLoginVisible = ref(false)
const showSetPasswordVisible = ref(false)
const showForgetPasswordVisible = ref(false)
const showSmallListVisible = ref(false)
const showAntiVisible = ref(false)
const antiIsForce = ref(false)
const showNoticeVisible = ref(false)
const noticeData = ref('')

//是否自动登录
const isAutoLogin = ref(store.state.isAutoLogin);
// 用户token
const userToken = computed(() => {return store.state.token})

//登录框初始化数据
const initData = reactive({
  agreement_url: "",
  agreement_title: "用户协议和隐私政策",
  agreement_default: "1"
})
const fastCreateAccount = ref();
//登录方式
const activeName = ref('accountLogin');
// 大号登录成功回传数据
const loginResult = ref({})
// 小号登录成功回传数据
const smallLoginResult = reactive({
  user_id:0,
  token:0
})

onMounted(()=>{
  init()
})

//初始化
const init = () => {
  randomAccount()
  tabsInit().then(({data}) => {
    initData.agreement_url = data.agreement_url
    initData.agreement_title = data.agreement_title
    initData.agreement_default = data.agreement_default
  })
}
//随机注册名
const randomAccount = () => {
  let createStr = 'x';
  createStr += String(getTime()).substring(String(getTime()).length - 8);
  createStr += String(Math.floor(Math.random() * (999 - 100)) + 100);
  fastCreateAccount.value = createStr;
}
// 调用登录逻辑
const callLogin = () => {
  if (userToken.value && isAutoLogin.value) {
    showSmallList()
  } else {
    // 用户未登陆
    showLogin()
  }
}
//切换块选择
const changTab = ({name}) => {
  randomAccount()
  activeName.value = name
}

// 显示登录弹窗
const showLogin = () => {
  closeAll()
  showLoginVisible.value = true
}
// 显示设置密码弹窗
const showSetPassword = () => {
  closeAll()
  showSetPasswordVisible.value = true
}
// 显示忘记密码弹窗
const showForgetPassword = () => {
  closeAll()
  showForgetPasswordVisible.value = true
}
// 展示小号列表
const showSmallList = () => {
  closeAll()
  showSmallListVisible.value = true
}
// 展示实名认证
const showAnti = () => {
  closeAll()
  showAntiVisible.value = true
}
// 实名认证关闭回调
const closeAntiDialog = () => {
  closeAll()
  showNotice()
}
// 展示公告
const showNotice = () => {
  closeAll()
  toolNotice().then(({data})=>{
    if(!data.show_notice) return handleLoginCallBack();
    showNoticeVisible.value = true;
    noticeData.value = data
  })
}
// 关闭公告回调
const closeNoticeDialog = () => {
  closeAll()
  handleLoginCallBack()
}

// 大号登录成功
const loginSuccess = (data) => {
  if(!data.set_pass){
    store.commit('setUser', data)
    Toast("登录成功");
    showSmallList()
  }else{
    loginResult.value = data
    showSetPassword()
  }
}
// 设置密码完成
const setPasswordCallback = () => {
  loginResult.value.set_pass = 0
  store.commit('setUser', loginResult.value)
  Toast("登录成功");
  showSmallList()
}

// 小号登录成功
const smallLoginSuccess = (data) => {
  ({id:smallLoginResult.user_id} = data);
  ({token:smallLoginResult.token} = data);
  closeAll()
  realAuthStatus().then(({data}) => {
    if (data.status && !data.user_age_status) {
      antiIsForce.value = data.is_force_real == 1 ? true : false
      showAnti()
    }else{
      //获取公告通知
      showNotice()
    }
  })
}
// 关闭所有弹窗
const closeAll = () => {
  showLoginVisible.value = false
  showSetPasswordVisible.value = false
  showForgetPasswordVisible.value = false
  showSmallListVisible.value = false
  showAntiVisible.value = false
  showNoticeVisible.value = false
}
// 登录逻辑完成
const handleLoginCallBack = ()=>{
  console.log('登陆成功')
  loginCallback(smallLoginResult)
  Toast({
    message: '可在浮球-个人中心内隐藏浮球哦～',
    position: 'top',
    className: 'float-toast',
    icon: require('@/assets/images/float_icon.png'),
  });
}

defineExpose({callLogin})
</script>

<style>
.default-dialog-pane {
  width: 343px;
  background: none;
  overflow: hidden;
}

.land-dialog-pane {
  width:240px;
  background: none;
  overflow: hidden;
}
</style>
