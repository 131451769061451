<template>
  <div class="dialog-pane-form">
    <validator-form @valid-success="onSubmit" :rules="userFormRules">
      <van-cell-group>
        <!--手机号-->
        <validator-field v-model="user.phone" :validator="userFormRules.phone" name="phone" placeholder="请输入手机号"
                   clearable :left-icon="require('@/assets/images/login/phone.png')"/>
        <!--验证码-->
        <validator-field v-model="user.sms_code" :validator="userFormRules.sms_code" name="sms_code" clearable
                   :left-icon="require('@/assets/images/login/sms.png')" placeholder="请输入短信验证码" class="addon-field">
          <template #button>
            <div class="input-addon" @click="sendValidateCode" :class="isDisabled ? 'not-click' : ''">
              {{ isDisabled ? `${count}s后重新获取` : "获取验证码" }}
            </div>
          </template>
        </validator-field>
      </van-cell-group>
      <div class="cell-agree">
        <van-checkbox v-model="agreeChecked">我已阅读并同意<a :href="initData.agreement_url"
                                                       target="_blank">《{{ initData.agreement_title }}》</a>
        </van-checkbox>
      </div>
      <div class="cell-button">
        <van-button round block type="primary" native-type="submit" size="small" :disabled="!agreeChecked">
          进入游戏
        </van-button>
      </div>
    </validator-form>
  </div>
</template>

<script>
import {CellGroup, Button, Checkbox, Toast} from 'vant';
import {ref, toRefs, reactive} from "vue";
import {useStore} from 'vuex'
import {phoneLogin} from '@/api/user'
import {smsRegister} from "@/api/verification";
import ValidatorForm from "@/components/ValidatorForm";
import ValidatorField from "@/components/ValidatorField";
import validator from "@/utils/formsValidator";

export default {
  name: "PhoneRegister",
  components: {
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    ValidatorForm,
    ValidatorField,
  },
  props: {
    initData: {
      type: Object,
      default: () => {
        return {
          agreement_url: "",
          agreement_title: "用户协议和隐私政策",
          agreement_default: "1"
        }
      }
    },
  },
  setup(props, context) {
    const {initData} = toRefs(props);// eslint-disable-line no-unused-vars
    const agreeChecked = ref(true);
    //store声明
    const store = useStore();
    const user = reactive({
      sms_code: '',
      phone: store.state.lastLoginPhone || '',
    })
    //倒计时
    const timer = ref();
    const count = ref();
    const isDisabled = ref(false);
    const form = ref();

    //表单校验规则
    const userFormRules = {
      phone: {
        label: '手机号',
        rules: "required;mobile",
      },
      sms_code:{
        label: '验证码',
        rules: "required;digits",
      }
    };
    //密码显示与隐藏
    const switchPassType = ref(false);

    //发送验证码倒计时
    const sendValidateCode = () => {
      let {isValid,msg} = validator.isValid(user.phone, userFormRules.phone)
      if(!isValid){
        Toast(msg);
        return false;
      }
      smsRegister(user.phone).then(() => {
        count.value = 60
        isDisabled.value = true
        timer.value = setInterval(() => {
          if (count.value > 0) {
            count.value--;
          } else {
            isDisabled.value = false;
            clearInterval(timer.value);
          }
        }, 1000)
      });
    }

    //表单提交
    const onSubmit = () => {
      phoneLogin(user).then((res) => {
        store.commit('setLastLoginPhone', user.phone);
        context.emit('loginSuccess', res.data)
      })
    };

    return {
      userFormRules,
      switchPassType,
      agreeChecked,
      timer,
      count,
      isDisabled,
      user,
      form,

      onSubmit,
      sendValidateCode,
      context,
    }
  }
}
</script>

<style scoped>

</style>
